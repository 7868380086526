import { FC, useEffect, useState } from 'react'

import AttentionContent from './attention-content'
import { LocalStorageKeys } from 'constants/storage'

const AttentionPopup: FC = () => {
  const [isHidden, setHidden] = useState(true)

  const close = () => {
    localStorage.setItem(LocalStorageKeys.ATTENTION_AGREE, 'true')
    setHidden(true)
  }

  useEffect(() => {
    const oldAttentionAgreement = Boolean(
      localStorage.getItem(LocalStorageKeys.ATTENTION_AGREE_OLD)
    )
    const currentAttentionAgreement = Boolean(
      localStorage.getItem(LocalStorageKeys.ATTENTION_AGREE)
    )
    if (oldAttentionAgreement && !currentAttentionAgreement)
      localStorage.setItem(LocalStorageKeys.ATTENTION_AGREE, 'true')
    setHidden(oldAttentionAgreement || currentAttentionAgreement)
  }, [])

  return <>{!isHidden && <AttentionContent close={close} />}</>
}

export default AttentionPopup
