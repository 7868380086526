import { FC, memo } from 'react'

import NavbarListItems from './navbar-list-items'

import { Pathname } from 'constants/data'

const navBarItems = [
  {
    link: `/${Pathname.WEBINAR}`,
    text: 'Вебинары',
    activeRoutes: [`/${Pathname.WEBINAR}/target`],
  },
  {
    link: `/${Pathname.LIBRARY}`,
    text: 'Библиотека',
    activeRoutes: [
      `/${Pathname.LIBRARY}/article`,
      `/${Pathname.LIBRARY}/video`,
      `/${Pathname.LIBRARY}/clinic`,
      `/${Pathname.LIBRARY}/podcast`,
      `/${Pathname.LIBRARY}/interactive`,
    ],
  },
  {
    link: `/${Pathname.CLINICAL}`,
    text: 'Клинические случаи',
  },
  {
    link: `/${Pathname.NMO_COURSE}`,
    text: 'Курсы НМО',
  },
  // {
  //   link: `/${Pathname.LECTURE}`,
  //   text: 'Лекторы',
  // },
  {
    link: `/${Pathname.CALCULATORS}/geriatric-status`,
    text: 'Шкалы',
    activeRoutes: [
      `/${Pathname.CALCULATORS}/geriatric-status`,
      `/${Pathname.CALCULATORS}/somatic-status`,
      `/${Pathname.CALCULATORS}/oabss`,
    ],
    isNew: true,
    isWithCounter: true,
  },
]

interface INavbarList {
  isHamburgerOpen: boolean
  pathname: string

  closeMenu: () => void
}

const NavbarList: FC<INavbarList> = ({ isHamburgerOpen, closeMenu, pathname }: INavbarList) => {
  return (
    <NavbarListItems
      closeMenu={closeMenu}
      pathname={pathname}
      isHamburgerOpen={isHamburgerOpen}
      navBarItems={navBarItems}
    />
  )
}
export default memo(NavbarList)
