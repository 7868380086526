/*
Полосочка закомменчена на время, планируем скоро ее вернуть, 
поэтому не удаляйте тут ничего, пожалуйста :)
*/

import { useState, FC, useRef, useEffect, useCallback, memo, ReactNode, useContext } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import clsx from 'clsx'

import NavbarBottom from './navbar-bottom/navbar-bottom'
import NavbarRightSide from './navbar-right-side/navbar-right-side'
import SearchButton from './buttons/search-button/search-button'
import NavbarList from './navbar-list/navbar-list'
import Phone from 'components/parts/phone/phone'
import Logo from 'components/parts/logo/logo'
const SearchField = dynamic(() => import('./search-field/search-field'))
// const InformLine = dynamic(() => import('./inform-line/inform-line'), {
//   loading: () => PreloadLine(),
// })
import usePCWindow from 'hooks/usePCWindow'
// import screenSizeContext from 'context/screenSizeContext'
import useNavbarHeight from 'store/navbar/useNavbarHeight'
// import Auth from 'store/auth'

import { enableDocumentScroll } from 'utils/handlerDocumentScroll'
import { NavbarState, lineHeight, lineHeightMobile } from './data'
// import { SessionStorageKeys } from 'constants/storage'
import { ScreenSize } from 'constants/media-constanst'
// import { MediaNames } from 'constants/media-constanst'
import { IInfromData } from 'constants/types/navbar.types'

import style from './navbar.module.scss'

export interface INavbar {
  noLines?: boolean
  informData?: IInfromData
  isNoAuth?: boolean
  isInformLineHide: boolean
  children: ReactNode
  noNavbarItems?: boolean
}

// const PreloadLine = () => {
//   const { windowSize } = useContext(screenSizeContext)
//   return (
//     <div
//       style={
//         windowSize === MediaNames.MOBILE_SMALL
//           ? { height: lineHeightMobile }
//           : { height: lineHeight }
//       }
//     ></div>
//   )
// }

const Navbar: FC<INavbar> = ({
  children,
  noLines = false,
  isInformLineHide,
  informData,
  isNoAuth,
  noNavbarItems,
}: INavbar) => {
  const { pathname } = useRouter()
  const navRef = useRef<HTMLDivElement>(null)
  const isMobile = usePCWindow(ScreenSize.mobile)
  const logoSize = usePCWindow(ScreenSize.big)
    ? { width: 179, height: 40 }
    : { width: 120, height: 26 }

  const { setNavbarHeight } = useNavbarHeight()
  // const { userProfile } = Auth.useAuth()
  // const { setCurrentProfile } = Auth.useHandleAuth()

  const initMenu = NavbarState.NOT_OPENED
  const [openedMenu, setOpenedMenu] = useState(initMenu)

  const closeMenu = () => {
    setOpenedMenu(NavbarState.NOT_OPENED)
  }

  const handleMenuState = useCallback((newState: string) => {
    setOpenedMenu((prev) => {
      if (newState === prev) return NavbarState.NOT_OPENED
      else return newState
    })
  }, [])

  // const handleInformLineClose = useCallback(() => {
  //   sessionStorage.setItem(SessionStorageKeys.INFORM_LINE_HIDDEN, 'true')
  //   setCurrentProfile({ ...userProfile, informData: { ...informData, lineClosed: true } })
  // }, [userProfile])

  const toggleHamburgerState = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    handleMenuState(NavbarState.HAMBURGER)
  }
  const toggleSearchInput = () => handleMenuState(NavbarState.SEARCH)

  const isSearchOpen = openedMenu === NavbarState.SEARCH
  const isHamburgerOpen = openedMenu === NavbarState.HAMBURGER

  useEffect(() => enableDocumentScroll(), [])

  useEffect(() => {
    const height = navRef?.current?.offsetHeight

    if (height) setNavbarHeight(height)
  }, [isInformLineHide, informData?.lineClosed])

  return (
    <>
      <header ref={navRef} className={style.header}>
        {/* {!isInformLineHide && informData && !informData.lineClosed && (
          <InformLine informData={informData} handleClose={handleInformLineClose} />
        )} */}

        <nav className={style.nav}>
          <div className={style.headerContent}>
            <div className={style.leftSide}>
              {!noNavbarItems && (
                <button
                  className={style.hamburger}
                  type="button"
                  aria-label="Меню"
                  onClick={toggleHamburgerState}
                >
                  <span className={style.hamburgerBox}>
                    <span
                      className={clsx(style.hamburgerInner, {
                        [style.hamburgerInnerActive]: isHamburgerOpen,
                      })}
                    />
                  </span>
                </button>
              )}
              <Logo isResponsive={noNavbarItems} />
            </div>

            {!noNavbarItems && (
              <NavbarList
                isHamburgerOpen={isHamburgerOpen}
                pathname={pathname}
                closeMenu={closeMenu}
              />
            )}
            {noNavbarItems && <Phone />}
            <div className={style.rightSide}>
              <div onClick={toggleSearchInput} className={style.searchIcon}>
                <SearchButton isSearchInputShown={isSearchOpen} />
              </div>
              <NavbarRightSide
                openedMenu={openedMenu}
                handleMenuState={handleMenuState}
                isNoAuth={isNoAuth}
              />
            </div>
          </div>
          {isSearchOpen && !isMobile && <SearchField closeMenu={closeMenu} />}
        </nav>
        {children}
        <NavbarBottom isSearchOpen={isSearchOpen} noLines={noLines} navRef={navRef} />
      </header>
    </>
  )
}

export default memo(Navbar)
