import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'

import usePopupScrollPosition from '../../hooks/usePopupScrollPosition'
const PortalWrap = dynamic(() => import('../portal-wrap/portal-wrap'), { ssr: false })

import { STATIC_URL } from '../../constants/data'

import styles from './popup-wrapper.module.scss'

const imgPath = {
  icon_close: `${STATIC_URL}/filters/icon_close.svg`,
}

interface PopupWrapper {
  children: ReactNode
  handlePopup?: (e: React.MouseEvent<HTMLDivElement>) => void
  popupBlockModificator?: string
  popupWrapperModificator?: string
  showBtnClose?: boolean
  isNoAuthPopup?: boolean
  enableOverflow?: boolean
  isScrollable?: boolean
  isFullScreen?: boolean
  isPopupScrollPosition?: boolean
  isBottomMobile?: boolean
}

const PopupWrapper: FC<PopupWrapper> = ({
  children,
  handlePopup,
  popupBlockModificator,
  popupWrapperModificator,
  showBtnClose = true,
  isNoAuthPopup,
  isFullScreen,
  enableOverflow,
  isScrollable,
  isBottomMobile,
  isPopupScrollPosition = true,
}: PopupWrapper) => {
  const blockPopup = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()

  usePopupScrollPosition(isPopupScrollPosition)

  return (
    <PortalWrap>
      <div
        className={clsx(styles.popupWrapper, {
          [styles.bottomMobile]: isBottomMobile,
          [styles.fullScreenWrapper]: isFullScreen,
          [popupWrapperModificator as string]: popupWrapperModificator,
        })}
        onClick={handlePopup}
      >
        <div
          className={clsx(styles.popupBlock, {
            [styles.overflowVisible]: enableOverflow,
            [styles.scrollableContent]: isScrollable,
            [styles.fullScreen]: isFullScreen,
            [popupBlockModificator as string]: popupBlockModificator,
          })}
          onClick={
            //TODO очень странная тема
            isNoAuthPopup
              ? () => {
                  'javascript: void 0'
                }
              : blockPopup
          }
        >
          {showBtnClose && (
            <img
              src={imgPath.icon_close}
              className={styles.btnClose}
              alt="icon close"
              decoding="async"
              loading="lazy"
              onClick={
                isNoAuthPopup
                  ? () => {
                      'javascript: void 0'
                    }
                  : handlePopup
              }
            />
          )}
          {children}
        </div>
      </div>
    </PortalWrap>
  )
}

export default PopupWrapper
