import { FC } from 'react'

import Button from '../../parts/button/button'
import PopupWrapper from 'containers/popup-wrapper/popup-wrapper'

import { buttonIdProfessionalAttention } from 'constants/clickableElementsId'

import styles from './popup-attention.module.scss'

interface IAttentionModal {
  close: () => void
}

const AttentionContent: FC<IAttentionModal> = ({ close }: IAttentionModal) => {
  return (
    <PopupWrapper showBtnClose={false}>
      <p className={styles.popupHeader}>Внимание</p>
      <div className={styles.content}>
        <p className={styles.firstParagraph}>
          Данный сайт содержит профессиональную специализированную информацию. Согласно действующему
          законодательству данные материалы могут быть доступны только медицинским работникам.
        </p>
        <p>
          Я подтверждаю, что являюсь специалистом с медицинским образованием и информация,
          полученная на сайте, будет использована мною только в профессиональной деятельности.
        </p>
      </div>
      <div className={styles.button} onClick={close}>
        <Button id={buttonIdProfessionalAttention}>Подтверждаю</Button>
      </div>
    </PopupWrapper>
  )
}

export default AttentionContent
